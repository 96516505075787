import request from '@/utils/request'

/**
 * 文件导入日志信息查询
 */
export function queryList() {
  return request({
    url: '/DataLogs/queryList',
    method: 'get'
  })
}
