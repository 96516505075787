<template>
  <div class="dataImport">
    <div class="part di_part mgr">
      <itemBg width="47" height="22" title="企业信息" />
      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(0)">下载模板</el-button>
      </div>
      <div class="flex-jc-start mt2h middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="actionUrl"
          :data="{ flag: 0 }"
          :on-exceed="handleExceed"
          :file-list="fileList0"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 0" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <div class="part di_part">
      <itemBg width="47" height="22" title="商品信息" />
      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(1)">下载模板</el-button>
      </div>
      <div class="flex-jc-start mt2h middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="actionUrl"
          :data="{ flag: 1 }"
          :on-exceed="handleExceed"
          :file-list="fileList1"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 1" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <div class="part di_part mgr">
      <itemBg width="47" height="22" title="站点销量" />
      <div class="flex-jc-start left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(2)">下载模板</el-button>
      </div>
      <div class="flex-jc-start middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="actionUrl"
          :on-change="fileChange"
          :data="{ flag: 2 }"
          :on-exceed="handleExceed"
          :file-list="fileList2"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 2" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <div class="part di_part">
      <itemBg width="47" height="22" title="物流信息" />

      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(3)">下载模板</el-button>
      </div>
      <div class="flex-jc-start middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="actionUrl"
          :data="{ flag: 3 }"
          :on-exceed="handleExceed"
          :file-list="fileList3"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 3" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <!-- 杨艳飞 -->
    <div class="part di_part mgr">
      <itemBg width="47" height="22" title="农产品销售网购统计" />
      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(4)">下载模板</el-button>
      </div>
      <div class="flex-jc-start mt2h middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="loginsticsUrl"
          :data="{ flag: 4 }"
          :on-exceed="handleExceed"
          :file-list="fileList0"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == '4'" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <div class="part di_part">
      <itemBg width="47" height="22" title="文创年销售明细" />

      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(5)">下载模板</el-button>
      </div>
      <div class="flex-jc-start middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="loginsticsUrl"
          :data="{ flag: 5 }"
          :on-exceed="handleExceed"
          :file-list="fileList3"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 5" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <!-- 杨艳飞 -->
    <div class="part di_part mgr">
      <itemBg width="47" height="22" title="旅游景点线上销售明细" />
      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(6)">下载模板</el-button>
      </div>
      <div class="flex-jc-start mt2h middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="loginsticsUrl"
          :data="{ flag: 6 }"
          :on-exceed="handleExceed"
          :file-list="fileList0"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 6" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
    <div class="part di_part">
      <itemBg width="47" height="22" title="物流上行寄件台账" />

      <div class="flex-jc-start mt2h left">
        <div class="white_txt">模板:</div>
        <el-button size="small" type="primary" @click="downLoad(7)">下载模板</el-button>
      </div>
      <div class="flex-jc-start middle">
        <div class="white_txt">选择导入文件:</div>
        <el-upload
          class="upload-demo"
          accept=".xls,.xlsx"
          :multiple="false"
          :action="loginsticsUrl"
          :data="{ flag: 7 }"
          :on-exceed="handleExceed"
          :file-list="fileList3"
          :on-success="handleSuccess"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="tip">只能上传xls/xlsx文件</div>
        </el-upload>
      </div>
      <div v-for="item in logInfo" v-show="item.remark == 7" :key="item.logsId" class="log right">
        <div class="log_txt">文件名称：{{ item.uploadFile }}</div>
        <div class="log_txt">上传时间：{{ item.finalTime }}</div>
        <div class="log_txt">处理结果：{{ item.finalResult }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import routeChange from '@/mixins/routeChange'
import itemBg from '@/components/itemBg'
import { queryList } from '@/api/dataImport'

export default {
  name: 'Home',
  components: { itemBg },
  mixins: [routeChange],
  data() {
    return {
      actionUrl: process.env.VUE_APP_BASE_API + '/Excel/importData',
      loginsticsUrl: process.env.VUE_APP_BASE_API + '/excel/import/importData',
      fileList0: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      logInfo: []
    }
  },
  created() {},
  mounted() {
    queryList().then((res) => {
      this.logInfo = res
    })
  },
  methods: {
    // 0：企业信息
    // 1：商品信息
    // 2：站点销量
    // 3：物流信息
    downLoad(id) {
      window.location = process.env.VUE_APP_BASE_API + '/Excel/templateDownload?flag=' + id
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      // console.log(file.raw)
      // this.fileList = []
      // this.fileList.push(file.raw)
      // console.log(this.fileList)
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList)
    },
    handlePreview(file) {
      // console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response) {
      // console.log(response)

      // if (this.fileList.length > 1) {
      //   console.log(this.fileList)
      //   this.fileList = this.fileList.pop()
      // }

      if (response.code === 200 && response.data) {
        const { flag, name, path } = response.data
        // const item = {
        //   url: ImgUrl,
        //   uid: putObjectResult.requestId
        // }
        // this.uploadConfig.fileList.push(item)
        this.$nextTick(() => {
          switch (flag) {
            case 0:
              this.fileList0 = []
              this.fileList0.push({
                name,
                path
              })
              break
            case 1:
              this.fileList1 = []
              this.fileList1.push({
                name,
                path
              })
              break
            case 2:
              this.fileList2 = []
              this.fileList2.push({
                name,
                path
              })
              break
            case 3:
              this.fileList3 = []
              this.fileList3.push({
                name,
                path
              })
              break
          }
        })
      } else {
        this.$message.error(response.msg)
      }
    }
  }
}
</script>
<style lang="scss">
@import './index';
</style>
