<template>
  <div class="item_bg" :style="{ width: width + 'vw', height: height + 'vh' }">
    <img
      class="line_left item_line"
      :style="{ height: height + 'vh' }"
      :src="require('@/assets/item_bg/line_left.png')"
      alt=""
    />
    <img
      class="line_right item_line"
      :style="{ height: height + 'vh' }"
      :src="require('@/assets/item_bg/line_right.png')"
      alt=""
    />
    <img class="line_up item_line" :src="require('@/assets/item_bg/line_up.png')" alt="" />
    <img class="line_down item_line" :src="require('@/assets/item_bg/line_down.png')" alt="" />
    <img class="corner_bottom_left corner_line" :src="require('@/assets/item_bg/Corner_bottom-left.png')" alt="" />
    <img class="corner_bottom_right corner_line" :src="require('@/assets/item_bg/Corner_bottom-right.png')" alt="" />
    <img class="corner_top_left corner_line" :src="require('@/assets/item_bg/Corner_top-left.png')" alt="" />
    <img class="corner_top_right corner_line" :src="require('@/assets/item_bg/Corner_top-right.png')" alt="" />
    <img v-if="title" class="title" :src="require('@/assets/item_bg/title.png')" alt="" />
    <div v-if="title" class="item_title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'ItemBg',
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss">
.item_bg {
  position: relative;
  .item_line {
    position: absolute;
    z-index: 1;
  }
  .line_left {
    top: 0;
    left: 0;
    width: 28px;
  }
  .line_right {
    top: 0;
    right: 0;
    width: 28px;
  }
  .line_up {
    top: 0;
    left: 0;
    height: 28px;
    width: 100%;
  }
  .line_down {
    bottom: 0;
    left: 0;
    height: 28px;
    width: 100%;
  }
  .corner_line {
    position: absolute;
    z-index: 2;
    height: 28px;
    width: 28px;
  }
  .corner_bottom_left {
    bottom: 0;
    left: 0;
  }
  .corner_bottom_right {
    bottom: 0;
    right: 0;
  }
  .corner_top_left {
    top: 0;
    left: 0;
  }
  .corner_top_right {
    top: 0;
    right: 0;
  }
  .title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 341px;
  }
  .item_title {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #00eaff;
    line-height: 42px;
  }
}
</style>
