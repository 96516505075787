import axios from 'axios'
// import { MessageBox } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

service.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    //   config.headers['Authorization'] = 'Bearer ' + getToken()
    // }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const res = response.data
      if (res.code === 200) {
        return res.data
      }
    } else {
      return response
    }
    // const res = response.data
    // // if (res.code === 2010 || res.code === 2011) {
    // if (res.code === 403) {
    //   const toPath = window.location.hash.replace('#', '') || ''
    //   MessageBox.confirm('登录失效，请重新登录', '提示信息', {
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then((res) => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.href = `/home?redirect=${toPath}`
    //       })
    //     })
    //     .catch((action) => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.href = `/home?redirect=${toPath}`
    //       })
    //     })
    //   return Promise.reject(new Error(res.message || 'Error'))
    // }
  },
  (error) => {
    // Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
